import { Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { RoleEnum } from '@graphql/queries'
import type { Account } from '@graphql/types'

interface Props {
  data: Account
}

const RoleCell: FC<Props> = ({ data: account }) => {
  const { role, disabledAt } = account

  return (
    <Text color={disabledAt ? 'fg.subtle' : ''} fontSize="sm">
      {role === RoleEnum.Viewer ? 'Viewer' : 'Editor'}
    </Text>
  )
}

export default RoleCell
