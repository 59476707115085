import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  StackDivider,
  useColorMode
} from '@chakra-ui/react'
import { type FC, type FormEventHandler, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import AiAssistantFiles from '@app/pages/maps/components/aiAssistantFiles'
import CopyEmbedCodeButton from '@app/pages/maps/components/map/copyEmbedCodeButton'
import DownloadDataButtons from '@app/pages/maps/components/map/downloadDataButtons'
import PopulateMetricsWithDummyData from '@app/pages/maps/components/map/populateMetricsWithDummyData'
import SendStrategyReviewButton from '@app/pages/maps/components/sendStrategyReviewButton'
import StrategyDeleteButton from '@app/pages/maps/components/strategyDeleteButton'
import StrategyFormAccess from '@app/pages/maps/components/strategyFormAccess'
import { FormAlert } from '@app/shared/forms'
import {
  Form,
  LabelAutocompleteInput,
  SlackAutocompleteInput,
  TextAreaInput,
  TextInput,
  ToggleInput,
  useForm
} from '@app/shared/rawForms'
import useToast from '@app/shared/toast'
import { useStore } from '@app/store'
import type { MapDomainStrategy } from '@app/types'

interface Props {
  isOpen?: boolean
  onClose?: () => void
}

const StrategySettings: FC<Props> = ({ isOpen = true, onClose = () => {} }) => {
  const { strategyId } = useParams()
  const strategy = useGetObject(strategyId, 'strategy')
  const updateObject = useStore.use.updateObject()
  const navigate = useNavigate()
  const { errors } = useForm({})
  const toast = useToast()
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  const navigateClose = () => {
    navigate('..')
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(async (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formValues = Object.fromEntries(formData.entries())
    const values: { id: string; labels?: string[] } = { ...formValues, id: strategyId }
    values.labels &&= JSON.parse(values.labels as unknown as string)

    try {
      await updateObject({ strategy: values }).then((response) => {
        const { errors: updateErrors, nodeObjects } = response?.data?.domainObjectUpdate || {}

        if (updateErrors.length) {
          throw new Error(updateErrors[0].message)
        }

        if (nodeObjects[0]) {
          const { labels } = nodeObjects[0] as MapDomainStrategy

          // @ts-expect-error labels are a massive time suck and should become real objects
          updateObject({ strategy: { id: strategyId, labels } }, true)
        }
      })

      toast({
        title: 'Updated Strategy',
        description: 'Successfully updated the strategy.'
      })

      onClose()

      navigateClose()
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error',
        description: error.message
      })
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} onEsc={navigateClose} onOverlayClick={navigateClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <Form id="strategy-settings-form" onSubmit={onSubmit}>
          <ModalCloseButton onClick={() => navigate('..')} />
          <ModalHeader>Map Settings</ModalHeader>
          <ModalBody>
            <Box p={isDarkMode ? 4 : 0} bg="bg.surface">
              <FormAlert description={errors?.global?.message as string} title="Failed to update the strategy!" />
              <Stack divider={<StackDivider />} spacing="5">
                <TextInput
                  name="name"
                  label="Name"
                  placeholder="Strategy name"
                  defaultValue={strategy.name}
                  errors={errors}
                />
                <TextAreaInput
                  name="description"
                  label="Description"
                  placeholder="Describe the strategy."
                  defaultValue={strategy.description}
                  errors={errors}
                />
                <>
                  <SlackAutocompleteInput
                    helperText="Choose a Slack channel below to have a weekly Slack digest delivered each Friday at 9 a.m. PT."
                    name="slackChannelId"
                    label="Slack channel"
                    defaultValue={strategy.slackChannel}
                    errors={errors}
                  />
                  {strategy.slackChannel && (
                    <Box mt={3}>
                      <SendStrategyReviewButton strategy={strategy} />
                    </Box>
                  )}
                </>
                <ToggleInput
                  name="showCorrelations"
                  label="Show correlation scores"
                  defaultValue={strategy.showCorrelations}
                  errors={errors}
                />
                <LabelAutocompleteInput
                  name="labels"
                  label="Labels"
                  defaultValue={strategy?.labels || []}
                  menuPortalTarget={document.body}
                />
                <StrategyFormAccess strategy={strategy} />
                <CopyEmbedCodeButton strategy={strategy} />
                <DownloadDataButtons strategy={strategy} />
                <PopulateMetricsWithDummyData strategy={strategy} />
                <TextAreaInput
                  name="aiContext"
                  label="Context"
                  placeholder="Provide information that would be useful for our AI assistants to know."
                  defaultValue={strategy.aiContext}
                />
                <AiAssistantFiles strategy={strategy} />
              </Stack>
            </Box>
          </ModalBody>
          <ModalFooter>
            <StrategyDeleteButton strategyId={strategy.id} />
            <Spacer />
            <ButtonGroup>
              <Button onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}

export default StrategySettings
