import {
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Switch,
  Text,
  useColorMode
} from '@chakra-ui/react'
import type { FC } from 'react'
import { BiBuildings, BiGroup, BiCreditCard, BiUser, BiExit, BiSun, BiMoon } from 'react-icons/bi'
import { RiAdminLine } from 'react-icons/ri'

import SidebarMenuItem from './sidebarMenuItem'
import UserButton, { type UserButtonProps } from './userButton'

import Can from '@app/shared/authorization/can'

type Props = UserButtonProps

const SidebarMenu: FC<Props> = ({ user }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  return (
    <Menu>
      <MenuButton>
        <UserButton user={user} />
      </MenuButton>
      <MenuList px="3" py="4" color="muted" shadow="lg">
        <Can I="view" an="admin">
          <SidebarMenuItem icon={<RiAdminLine />} target="/admin" title="Admin" isExternal />
        </Can>
        <Can I="update" an="organization">
          <SidebarMenuItem icon={<BiBuildings />} target="/settings/organization/edit" title="Organization" />
        </Can>
        <Can I="update" an="account">
          <SidebarMenuItem icon={<BiGroup />} target="/settings/organization/accounts" title="Accounts & Invites" />
        </Can>
        <Can I="update" a="payment">
          <SidebarMenuItem icon={<BiCreditCard />} target="/settings/billing" title="Billing" />
        </Can>
        <MenuDivider />
        <MenuItem
          closeOnSelect={false}
          icon={isDarkMode ? <BiMoon /> : <BiSun />}
          onClick={toggleColorMode}
          rounded="md"
        >
          <HStack>
            <Text>Dark Mode</Text>
            <Spacer />
            <Switch colorScheme="gray" id="dark-mode" isChecked={isDarkMode} />
          </HStack>
        </MenuItem>
        <MenuDivider />
        <SidebarMenuItem icon={<BiUser />} target="/settings/user/edit" title="Profile" />
        <SidebarMenuItem icon={<BiExit />} target="/sign_out" title="Logout" isExternal />
      </MenuList>
    </Menu>
  )
}

export default SidebarMenu
