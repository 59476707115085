import { Text } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'

import type { Account } from '@graphql/types'

interface Props {
  data: Account
}

const OrganizationCell: FC<Props> = ({ data: account }) => (
  <Text fontSize="sm" title={account.organizationName}>
    {truncate(account.organizationName, { length: 50 })}
  </Text>
)

export default OrganizationCell
