import type { BoxProps } from '@chakra-ui/react'
import { HStack, Spacer, Stack } from '@chakra-ui/react'
import { useViewport } from '@xyflow/react'
import type { FC } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { Card, CardRollupType, CardSource } from '../components'
import CardLabels from '../components/cardLabels'
import { CardProvider, useCardContext } from '../contexts/cardContext'

import CardMetricGoals from './cardMetricGoals'
import CardStats from './cardStats'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import { aiInsightsShadow } from '@app/lib/globals'
import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import MetricTypePopover from '@app/next/metricTypePopover'
import OwnerPopover from '@app/next/ownerPopover'
import CollapsedNodes from '@app/pages/maps/components/nodes/components/collapsedNodes'
import { scaleSizeToMapZoom } from '@app/pages/maps/components/nodes/helpers'
import cardAvatarSize from '@app/shared/cards/cardAvatarSize'
import cardFontSize from '@app/shared/cards/cardFontSize'
import CardDetailsLink from '@app/shared/cards/components/cardDetailsLink'
import CardSection from '@app/shared/cards/components/cardSection'
import CardTitle from '@app/shared/cards/components/cardTitle'
import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import PulsingCircle from '@app/shared/pulsingCircle'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import type { MapDomainNode } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'
import type { Metric } from '@graphql/queries'

interface Props extends BoxProps {
  metric?: Metric
  node?: MapDomainNode
}

export const MetricCard: FC<Props> = ({ metric, node, ...rest }) => {
  const { size } = useCardContext()
  const { strategyId } = useParams()
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  const [searchParams] = useSearchParams()
  const showMetricData = searchParams.get('metricData') !== 'false'

  const { zoom } = useViewport()

  const {
    id,
    rollUp,
    sourceName,
    goals,
    strategyStats: metricStats,
    strategyNodeData,
    externalUrl,
    externalUrlTitle,
    recentActivity,
    labels,
    aiGenerated
  } = metric

  const strategyStats = strategyNodeData?.strategyStats

  // combine the stats display better so these checks naturally cluster together
  const hasMetricStats = metricStats?.some((stat) => stat.total || stat.change) || false
  const hasStrategyStats = strategyStats?.some((stat) => stat.total || stat.change) || false
  const showStats = (hasMetricStats || hasStrategyStats) && showMetricData
  const goalIds = goals ? goals.map((goal) => goal.id) : []
  const route = isAnonymousUser ? null : `/strategy/${strategyId}/map/metric/${metric.id}`

  const pulseSize = scaleSizeToMapZoom(24, zoom, 44)
  const avatarSize = cardAvatarSize('xs', size)
  const fontSize = cardFontSize('sm', size)

  const boxProps: BoxProps = {}
  if (aiGenerated) {
    boxProps.boxShadow = aiInsightsShadow
  }

  return (
    <Card {...boxProps} {...rest} data-cy="metric-card">
      <CardTypeSection title="Metric /" icon={ICON_MAP.Metric} color={COLOR_MAP.Metric} route={route} size={size}>
        <MetricTypePopover metric={metric} fontWeight="semibold" size={size} />
      </CardTypeSection>
      <CardSection size={size}>
        <PulsingCircle isActive={!!recentActivity} size={pulseSize} link={`metric/${id}/events`} />
        <Stack>
          <CardTitle domainObject={metric} name="name" size={size} />
          {/* {description && <MarkdownDisplay text={description} fontSize={fontSize} />} */}
          {/* {technicalDescription && <MarkdownDisplay text={technicalDescription} fontSize={fontSize} />} */}
          {externalUrl && <CardDetailsLink url={externalUrl} text={externalUrlTitle} size={size} />}
          {showStats && <CardStats metric={metric} size={size} />}
        </Stack>
      </CardSection>
      {goalIds.length > 0 && showMetricData && (
        <CardSection size={size}>
          <CardMetricGoals metric={metric} goalIds={goalIds} size={size} />
        </CardSection>
      )}
      {labels?.length && <CardLabels fieldName="labels" domainObject={metric} size={size} />}
      <CardSection size={size}>
        <HStack>
          <OwnerPopover fontSize={fontSize} object={metric} avatarProps={{ size: avatarSize }} showName />
          <Spacer />
          {showStats && <CardRollupType fieldName="rollUp" type={rollUp} size={size} />}
          <CardSource sourceName={sourceName} size={size} color="muted" />
          <ConfidenceRatingPopover domainObject={metric} fontSize={fontSize} size={size} />
        </HStack>
      </CardSection>
      {node && <CollapsedNodes node={node} />}
    </Card>
  )
}

export const MetricCardWithContext = withBasicProvider(CardProvider)(MetricCard)
